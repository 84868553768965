/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAwLGAkpu-ku8rbZ7dkAbokA3uBp8mBdJ0",
  "appId": "1:614908549661:web:2c3e06726cbd159b4c44f4",
  "authDomain": "schooldog-i-bremen-ga.firebaseapp.com",
  "measurementId": "G-ZQNCE47E97",
  "messagingSenderId": "614908549661",
  "project": "schooldog-i-bremen-ga",
  "projectId": "schooldog-i-bremen-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-bremen-ga.appspot.com"
}
